import { Component, HostListener } from "@angular/core";
import { AuthServices } from './services/auth.service';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "AlphaSeed-Admin";
constructor(public authservice: AuthServices ){
this.authservice.checkTokenExpiration();
}
  @HostListener("document:click", ["$event"]) onclick(e) {
    if (e.target.classList.contains("nav-link")) {
      (<NodeList>document.querySelectorAll(".kyc_info_sec")).forEach(
        (item: HTMLElement) => {
          item.style.display = "none";
        }
      );
    }
  }
  onActivate(event) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
   
}
}
