import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthServices, AuthServices as Auth } from "../services/auth.service";
import { query } from "@angular/animations";
import { ToastrService } from "ngx-toastr";
import { TnNotificationService } from "../services/tn-notification.service";
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  AuthService,
  SocialUser
} from "angularx-social-login";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submit: boolean = false;
  user: SocialUser;
  //rememberMe: boolean;
  userEmail: any = "";
  password: any = "";
  fieldTextType: boolean;
  loginSuccess = false;

  passwordNotExit = false;
  emailFromJoin: any;
  userEmailValue: string;
  emailNotExist = false;
  emailExistErr: string;
  emailExists = false;
  emailNotVerify = false;
  wrongPwdAttempts = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthServices,
    public toastrService: ToastrService,
    private notificationService: TnNotificationService,
    private serviceAuth: AuthService,
    private activatedRoute: ActivatedRoute,
    public route: ActivatedRoute
  ) { }

  ONCHANGE(e) {
    var re = /[`~!#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/;
    if (re.test(e.target.value)) {
      e.preventDefault();
    }
  }

  ngOnInit() {
    this.clearlocalStorage();
    this.route.queryParams.subscribe((res: { email: string }) => {
      this.userEmail = res.email;
    });
    this.activatedRoute.queryParamMap.subscribe((data: any) => {
      this.emailFromJoin = data.params.email;
    });
    if (localStorage.getItem("email")) {
      this.userEmail = localStorage.getItem("email");
    }
    if (localStorage.getItem("password")) {
      this.password = localStorage.getItem("password");
    }
    this.loginForm = this.formBuilder.group({
      email: ["", [
        Validators.required,
        Validators.email,
        Validators.pattern(
          "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")
      ]],
      password: ["", Validators.required],
      //rememberMe: [""]  
    });
    this.serviceAuth.authState.subscribe(user => {
      this.user = user;
    });
  }


  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  clearlocalStorage() {
    localStorage.clear();
  }
  socialSignIn(socialProvider) {
    if (socialProvider === "google") {
      this.serviceAuth
        .signIn(GoogleLoginProvider.PROVIDER_ID)
        .then((data: any) => {
          const queryParams = {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            socialMedia: true,
            provider: socialProvider,
            token: data.idToken,
            authToken: data.authToken
          };
          const verifyEmail = {
            // tslint:disable-next-line: object-literal-shorthand
            email: data.email
          };
          this.authService.checkUserEmail(verifyEmail).subscribe((response: any) => {
          },
            err => {
              if (err.error.code === 403) {
                this.authService
                  .socialLogin(queryParams)
                  .subscribe((response: any) => {
                    if (response.code === 200 || response.code === 201) {
                      localStorage.setItem("currentUser", response.result.accessToken);
                      // localStorage.setItem("signinMethod", 'google');
                      localStorage.setItem("uuid", response.result.user.uuid);
                      //this.notificationService.successToast("Login Successfully");
                      // this.loginSuccess = true;
                      setTimeout(() => {
                        this.router.navigate(["/home-main"]);
                      }, 1000);
                    }
                  });
              } else if (err.error.code === 404) {
                this.authService.setSocialMediaData(queryParams)
                this.router.navigate(["/terms-condotion"]);
              }
            });
        });
    } else if (socialProvider === "facebook") {
      this.serviceAuth
        .signIn(FacebookLoginProvider.PROVIDER_ID)
        .then((data: any) => {
          localStorage.setItem("profileImageUrl", data.photoUrl);
          const queryParams = {
            email: data.facebook.email,
            firstName: data.firstName,
            lastName: data.lastName,
            socialMedia: true,
            provider: socialProvider,
            authToken: data.authToken
          };
          const verifyEmail = {
            // tslint:disable-next-line: object-literal-shorthand
            email: data.facebook.email
          };
          this.authService.checkUserEmail(verifyEmail).subscribe((response: any) => {
          },
            err => {
              if (err.error.code === 403) {
                this.authService
                  .socialLogin(queryParams)
                  .subscribe((response: any) => {
                    if (response.code === 200 || response.code === 201) {
                      localStorage.setItem("currentUser", response.result.accessToken);
                      // localStorage.setItem("signinMethod", 'google');
                      localStorage.setItem("uuid", response.result.user.uuid);
                      //this.notificationService.successToast("Login Successfully");
                      // this.loginSuccess = true;
                      setTimeout(() => {
                        this.router.navigate(["/home-main"]);
                      }, 1000);
                    }
                  });
              } else if (err.error.code === 404) {
                this.authService.setSocialMediaData(queryParams)
                this.router.navigate(["/terms-condotion"]);
              }
            });

          // this.authService
          //   .socialLogin(queryParams)
          //   .subscribe((response: any) => {
          //     if (response.code === 200 || response.code === 201) {
          //       localStorage.setItem("currentUser", response.result.accessToken);
          //       localStorage.setItem("uuid", response.result.user.uuid);
          //       // localStorage.setItem("signinMethod", 'facebook');
          //       //this.notificationService.successToast("Login Successfully");
          //       this.loginSuccess = true;
          //       setTimeout(() => {
          //         this.router.navigate(["/home-main"]);
          //       }, 1000);
          //     }
          //   });

        });
    }
  }

  // forgotPasswordMail(emailId) {
  //   this.authService.forgotPasswordMail({ email: emailId }).subscribe(
  //     (response: any) => {
  //       if (response.code === 200) {
  //         this.notificationService.successToast(
  //           "Please check the link sent to " + emailId + "."
  //         );
  //       }
  //     },
  //     err => {
  //       if (err.error.code === 401 || err.error.code === 400) {
  //         this.notificationService.errorToast("Please check the emailId");
  //       } else if (err.error.code === 406) {
  //         this.notificationService.errorToast(
  //           "User registered through Social media Login!"
  //         );
  //       } else if (err.error.code === 417) {
  //         this.notificationService.errorToast(
  //           "User Email not verified!"
  //         );
  //       }
  //     }
  //   );
  // }

  login(params) {
    const queryParams = {
      email: params.email,
      password: params.password
    };
    this.submit = true;
    this.authService.login(queryParams).subscribe(
      (data: any) => {
        if (data.code === 200) {
          // if (params.rememberMe) {
          //   localStorage.setItem("email", params.email);
          //   localStorage.setItem("password", params.password);
          // }
          this.loginForm.reset();
          this.loginSuccess = true;
          if (data['result'].verify2fa == true) {
            this.authService.setRequestId(data['result']);
            this.router.navigate(['/otp']);
          } else {
            localStorage.setItem("currentUser", data.result.accessToken);
            localStorage.setItem("uuid", data.result.user.uuid);
            localStorage.setItem("userId", data.result.user.id);
            // this.notificationService.successToast("User Login Successfully");
            this.router.navigate(["/home-main"]);
          }
        }
      },
      err => {
        if (
          err.error.code === 401 &&
          err.error.result === "User Account Deleted"
        ) {
          this.notificationService.warningToast(
            "Your Account has been Blocked, Please contact admin"
          );
        } else if (err.error.code === 401) {
          //this.notificationService.errorToast("Email not verified");
          this.emailNotVerify = true;
        } else if (err.error.code === 403) {
          this.passwordNotExit = true;
          // this.notificationService.infoToast("Incorrect Email Or Password");
          this.passwordNotExit = true;
          // this.emailNotExist = true;
        } else if (err.error.code === 308) {
          this.notificationService.warningToast(
            "Please Login Using Social Signin"
          );
        } else if (
          err.error.code === 404 &&
          err.error.result === "Email not found"
        ) {
          this.passwordNotExit = true;
          // this.emailNotExist = true;
          // this.notificationService.warningToast("Email or password Invalid");
        }
        else if (err.error.code === 423 &&
          err.error.result === 'Multiple Wrong Attempts'
        ) {
          this.passwordNotExit = false;
          this.wrongPwdAttempts = true;
        }
        else if (err.error.code === 404) {
          this.passwordNotExit = true;
          // this.emailNotExist = true;
          // this.notificationService.warningToast("Email or password Invalid");
        } else if (err.error.code === 400) {
          this.passwordNotExit = true;
          // this.emailNotExist = true;
          // this.notificationService.warningToast("Email or password Invalid");
        }
      }
    );

    // this._authService.login(data).subscribe((response) => {
    // 	this._authService.storeDetailsAfterLogin(response.result);
    // 	this.router.navigate(['/programs'])
    // }, (error) => {
    // 	this.submit = false;
    // 	this._messageHandler.errorMessage(error.error.message || ErrorMessage.commonError);
    // });
  }
  checkEmail(email) {
    const verifyEmailBody = {
      // tslint:disable-next-line: object-literal-shorthand
      email: email
    };
    this.authService.checkUserEmail(verifyEmailBody).subscribe((response: any) => {
      console.log(response.code);
    },
      err => {
        if (err.error.code === 403) {
          this.emailExists = true;
          this.emailNotExist = false;
          this.emailExistErr = err.error.result;
        } else if (err.error.code === 404) {
          this.emailExists = false;
          this.emailNotExist = true;
        }
      });
  }


}
